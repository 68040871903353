export const environment = {
  production: false,
  baseUrl: 'https://dev-api.friskus.com/api/v1',
  googleMapKey: 'AIzaSyDltOmOTBCiHBRlKfeukaziheQ_fuBHUhA',
  uploadCareKey: '89c8f3b9a267f0426d6a',
  activityTypeVolunteerUUID: '9f2c7214-79eb-4863-abdd-ad47262f72d5',
  isLangRedirectEnabled: false,
  apiUrl: {
    rss: 'https://dev-api.herokuapp.com/feed',
    event: 'https://dev-api.friskus.com/api/v1',
    group: 'https://dev-api.friskus.com/api/v1',
    identity: 'https://dev-api.friskus.com/api/v1',
    user: 'https://dev-api.friskus.com/api/v1',
    messenger: 'https://dev-api.friskus.com/api/v1',
    card: 'https://dev-api.friskus.com/api/v1',
    cardV2: 'https://dev-api.friskus.com/api/v2',
    notifications: 'https://dev-api.friskus.com/api/v1',
    tickets: 'https://dev-api.friskus.com/api/v1',
  },
  backofficeUrl: 'https://dev-api.friskus.com',
  cards: {
    provider_id: 'c16d46e3-152d-4280-a622-64dee1f09593'
  },
  bugsnagKey: '029a2770fea541d218b1b2e22362b1cd',
  gtmId: '',
  defaultHasLeisureCard: true,
  hasSubsidiaryOrg: true,
  prefix: 'dev',
  news: false,
  clarityProjectId: 'qv746rqsk3'
};
